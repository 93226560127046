<template>
    <div>
        <van-nav-bar title="添加员工账号" left-text="返回" left-arrow @click-left="onClickLeft"/>
        <van-cell-group>
            <van-field v-model="account" label="账号" placeholder="请输入账号(不得小于六位)" />
            <van-field v-model="pwd" label="密码" placeholder="请输入密码（不得小于六位）" />
            <van-field v-model="pwdConfirm" label="确认密码" placeholder="请输确认密码" />
            <van-field v-model="name" label="员工姓名" placeholder="请输入员工姓名" />
            <van-field  label="是否启用" placeholder="请输入员工姓名" >
                <div slot="input"></div>
                <van-radio-group direction="horizontal" slot="extra" v-model="radio">
                    <van-radio name="1">启用</van-radio>
                    <van-radio name="0">禁用</van-radio>
                </van-radio-group>
            </van-field>
        </van-cell-group>
        <div style="margin-top: 10px;text-align: center;">
            <van-button @click="addAccount" type="info">添加账号</van-button>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return{
            account: '',
            pwd: '',
            pwdConfirm: '',
            name: '',
            radio: '1',
            auth: localStorage.getItem('auth')
        }
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1)
        },
        addAccount(){
            if(this.auth == 0){
                Toast.fail('该功能只对管理员开放')
            }else{
                if(this.account == ''){
                    Toast.fail('请输入账号');
                }else if(this.pwd == ''){
                    Toast.fail('请输入密码');
                }else if(this.pwd.length < 6){
                    Toast.fail('密码不得小于六位');
                }else if(this.name == ''){
                    Toast.fail('请输入员工姓名');
                }else if(this.pwdConfirm == ''){
                    Toast.fail('请输入确认密码');
                }else if(this.pwd != this.pwdConfirm){
                    Toast.fail('两次密码输入不一致');
                }else{
                    let nonce = Math.round(new Date() / 60000).toString(32)
                    this.$axios.post(
                        this.url + '/setting/accountAdd',
                        {
                            nonce: nonce,
                            token: localStorage.getItem('token'),
                            username: this.account,
                            password: this.pwd,
                            realname: this.name,
                            status: this.radio
                        }).then( res=>{                    
                            if(res.data.code == 0){
                                Toast.success('添加成功');
                                this.account = ''
                                this.pwd = ''
                                this.pwdConfirm = ''
                                this.name = ''
                                this.radio = '1'                           
                            }else if(res.data.code == 20001){
                                this.$router.replace('/login')
                            }else{
                                Toast.fail(res.data.msg);
                            }
                        })
                }
            }

        }
    }
}
</script>