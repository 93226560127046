<template>
    <div>
        <van-nav-bar title="绑定企业信息" left-text="返回" left-arrow @click-left="onClickLeft"/>
        <van-cell-group>
            <van-field v-model="cname" label="企业名称" placeholder="请输入企业名称" />
            <van-field v-model="sname" label="简称" placeholder="请输入简称" />
            <van-field v-model="name" label="负责人名字" placeholder="请输入负责人名字" />
            <van-field v-model="phone" label="联系方式" placeholder="请输入联系方式" />
        </van-cell-group>
        <div style="margin-top: 10px;text-align: center;">
            <van-button @click="save" type="info">保存信息</van-button>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
export default {
    data() {
        return{
            cname: '',
            sname: '',
            name: '',
            phone: '',
            info: [],
            auth: localStorage.getItem('auth')
        }
    },
    mounted(){
        this.getInfo()
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1)
        },
        save(){
            if(this.auth == 0){
                Toast.fail('该功能只对管理员开放')
            }else{
                if(this.cname == ''){
                    Toast.fail('请输入企业名称')
                }else if(this.sname == ''){
                    Toast.fail('请输入简称')
                }else if(this.name == ''){
                    Toast.fail('请输入负责人名字')
                }else if(this.phone == ''){
                    Toast.fail('请输入联系方式')
                }else{
                    let nonce = Math.round(new Date() / 60000).toString(32)
                    this.$axios.post(
                        this.url + '/setting/Information',
                        {
                            nonce: nonce,
                            token: localStorage.getItem('token'),
                            update: 1,
                            name: this.cname,
                            alias: this.sname,
                            director: this.name,
                            contact_info: this.phone
                        }).then( res=>{                    
                            if(res.data.code == 0){
                                Toast.success('保存成功')
                                this.getInfo()
                            }
                        })
                }
            }

        },
        getInfo(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/setting/Information',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),

                }).then( res=>{                    
                    console.log(res)
                    this.cname = res.data.data.info.name
                    this.sname = res.data.data.info.alias
                    this.name = res.data.data.info.director
                    this.phone = res.data.data.info.contact_info
                })
        }
    }
}
</script>