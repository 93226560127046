import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import moment from "moment"
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
Vue.prototype.$axios = axios
Vue.prototype.url = 'https://yue.jptrip.net/h5'
Vue.use(Vant);
Vue.config.productionTip = false
const userAgent = navigator.userAgent.toLowerCase();
const isPC = !/(iphone|ipad|android|mobile)/i.test(userAgent);
if(isPC){
  window.location.href ='https://guest.jptrip.net'
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
