<template>
    <div>
        <p style="margin-top: 25vh;font-size: 30px;text-align:center">注册账号</p>
        <div style="width:95%;margin-left: 2.5%">
            <van-cell-group :border="false" >
                <van-field :border="false" v-model="account" label="登录账号" placeholder="请输入登录账号" />
                <van-field :border="false"  v-model="pwd" label="密码" type="password" placeholder="请输入密码" />
                <van-field :border="false"  v-model="pwdConfirm" label="确认密码" type="password" placeholder="请输入密码" />
            </van-cell-group>
            <div style="width:95%;padding:10px 16px;;display:flex;font-size:12px;color: #999;margin-top: 5px">
                <div >注意：密码至少六位，不支持汉字</div>
            </div>
            <div key="" style="width:95%;padding:10px 16px;display:flex;font-size:12px;margin-top: 5px;justify-content: space-between;">
                <van-radio-group  direction="horizontal"  v-model="radio">
                    <van-radio icon-size="15px" name="1">同意<a style="color:#576b95" @click="goAgreement">《系统使用协议》</a></van-radio>
                </van-radio-group>
                <div @click="goLogin" style="color: #999">去登录</div>
            </div>
            <div style="text-align:center">
                
                <van-button type="info" @click="register" size="large" style="width: 80%;margin-top: 10px;">确认注册</van-button>
            </div>
            
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';

export default {
    data() {
        return{
            value: '',
            radio: '',
            account: '',
            pwd: '',
            pwdConfirm: ''
        }
    },
    methods:{
        goAgreement(){
            this.$router.push('/agreement')
        },
        goLogin(){
            this.$router.replace('/login')
        },
        register(){
            if(this.radio == ''){
                Toast.fail('请同意使用协议');
            }else if(this.account == ''){
                Toast.fail('请输入账号');
            }else if(this.pwd == ''){
                Toast.fail('请输入密码');
            }else if(this.pwd.length < 6){
                Toast.fail('密码不得小于六位');
            }else if(this.pwdConfirm == ''){
                Toast.fail('请输入确认密码');
            }else if(this.pwd != this.pwdConfirm){
                Toast.fail('两次密码输入不一致');
            }else{
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/user/registration',
                    {
                        nonce: nonce,
                        username: this.account,
                        password: this.pwd
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            Toast.success('注册成功,即将跳转登录页');
                            setTimeout(()=>{
                                this.$router.replace('/login')
                            },1500)
                        }
                    })
            }
        }
    }
}
</script>