import { render, staticRenderFns } from "./bindCompanyInfo.vue?vue&type=template&id=149917c5"
import script from "./bindCompanyInfo.vue?vue&type=script&lang=js"
export * from "./bindCompanyInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports