<template>
    <div style="text-align:center">
        <van-nav-bar title="忘记密码" left-text="返回" left-arrow @click-left="onClickLeft"/>
        <div style="margin-top: 20px;font-size: 20px">
            联系客服找回您的密码
        </div>
        <van-image style="width:100px;margin-top: 20px" :src="img" ></van-image>
        <div style="margin-top: 20px;color:#999">注意：如果是员工账号忘记密码，请联系公司管理员进行找回。平台客服只能提供公司管理员密码找回服务。</div>
    </div>

</template>
<script>
export default {
    data() {
        return{
            img: ''
        }
    },
    mounted(){
        this.getQR()
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1)
        },
        getQR(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/user/kefuQrCode',
                {
                    nonce: nonce,
                }).then( res=>{                    
                    if(res.data.code == 0){
                        this.img = res.data.data.src
                    }
                })
        }
    }
}
</script>