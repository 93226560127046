<template>
    <div style="padding-bottom: 55px;">
        

        <van-popup position="bottom" :style="{ height: '30%' }" v-model="chooseTypePickerShow">
            <van-picker
            title="选择用途"
            show-toolbar
            :columns="columns" 
            />
        </van-popup>
        <van-popup position="bottom" :style="{ height: '30%' }" v-model="chooseCurrencyPickerShow">
            <van-picker
            title="选择币种"
            show-toolbar
            :columns="columns2" 
            />
        </van-popup>
        <van-popup position="bottom" :style="{ height: '40%' }"  v-model="chooseTimePickerShow">
            <van-datetime-picker
            v-model="date"
            type="datetime"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="chooseTime"
            @cancel ='cancelTime'
            />
        </van-popup>
        <div style="width:95%;margin-left: 2.5%" v-for="(item,index) in formList" :key="index">
            <van-field  :border="false">
                <van-button v-if="index > 0" slot="extra" size="mini" @click="delIssue(index)" type="danger">删除</van-button>
            </van-field>
            <div style="text-align: left;padding-top: 10px;padding-bottom: 10px;font-size:15px;font-weight: bold;">
                地址和时间
            </div>
            <van-cell-group :border="false">
                <van-field required :border="false" v-model="item.departure_place" label="出发地" placeholder="请输入出发地" />
                <van-field required :border="false"  readonly  @click="chooseTimePicker(index)"  v-model="item.departure_time" label="出发时间" placeholder="请输入出发时间" >
                </van-field>
                <van-field required :border="false"   v-model="item.destination" label="目的地" placeholder="请输入目的地" />
                <van-field required :border="false"  v-model="item.use_to" label="约车用途" placeholder="请输选择用车用途" >
                    <div slot="input"></div>
                    <template #input>
                        <van-radio-group v-model="item.use_to" direction="horizontal">
                            <van-radio v-for="item in columns" :key="item" :name="item">{{ item }}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </van-cell-group>
            <div style="text-align: left;padding-top: 10px;padding-bottom: 10px;font-size:15px;font-weight: bold;">
                联系方式和费用
            </div>
            <van-cell-group :border="false">
                <van-field required :border="false" v-model="item.telephone" label="联系电话" placeholder="请输入联系电话" />
                <van-field  :border="false"   v-model="item.wechat" label="联系微信号" placeholder="请输入联系微信号" />
                <van-field  :border="false"   v-model="item.contacts" label="联系人" placeholder="请输入联系人" />
                <van-field required :border="false"    v-model="item.fee" label="约车费用" type="number" placeholder="请输入约车费用" >
                    <template #button>
                        <van-radio-group v-model="item.unit" direction="horizontal">
                            <van-radio v-for="item in columns2" :key="item" :name="item">{{ item }}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </van-cell-group>
            <div style="text-align: left;padding-top: 10px;padding-bottom: 10px;font-size:15px;font-weight: bold;">
                其他要求
            </div>
            <van-cell-group :border="false">
                <van-field  :border="false" required type="digit" min="1" max="7" v-model="item.duration" label="用车时长" placeholder="请输入用车时长（最少1天最长7天）" />
                <van-field  :border="false"  type="digit"   v-model="item.number" label="乘车人数" length="10" placeholder="请输入乘车人数" />
                <van-field  :border="false" maxlength="50"  v-model="item.departure_loc" label="具体出发点" placeholder="请输入具体出发点" />
                <van-field  :border="false" maxlength="50"   v-model="item.destination_loc" label="具体目的地" placeholder="请输入具体目的地" />
                <van-field  :border="false" maxlength="20"  v-model="item.note" label="对车辆要求" placeholder="请输入对车辆要求" />
                <van-field  :border="false"  maxlength="20" v-model="item.case" label="乘客特殊情况" placeholder="请输入特殊情况" />
                <van-field  :border="false"  maxlength="20" v-model="item.annex" label="携带行李状况" placeholder="请输入携带行李状况" />
                <van-field  :border="false" maxlength="100"  v-model="item.remark" label="其他相关备注" placeholder="请输入其他备注" />
            </van-cell-group>
            <van-divider />

        </div>
        <div style="display:flex;justify-content: space-around;margin-top: 20px">
            <van-button style="width:40%" @click="addOrder" type="default">添加新用车单</van-button>
            <van-button style="width:40%" @click="submit"  type="info">一键发布</van-button>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item  icon="records-o">发布预约</van-tabbar-item>
            <van-tabbar-item to="/orderManagement" icon="orders-o">用车单管理</van-tabbar-item>
            <van-tabbar-item  to='/setting' icon="setting-o">系统设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import { Toast } from 'vant';
import moment from "moment"
export default {
    data() {
        return{
            active: 0,
            value: '',
            date: '',
            chooseTimePickerShow: false,
            chooseTypePickerShow: false,
            chooseCurrencyPickerShow: false,
            minDate: new Date(),
            maxDate: '',
            currentDate: new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000),
            columns: [],
            columns2: [],
            auth: localStorage.getItem('auth'),
            formList:[
                {
                    departure_place	: '',
                    destination: '',
                    departure_time: '',
                    use_to: undefined,
                    telephone: '',
                    wechat: '',
                    contacts: '',
                    fee: '',
                    duration: '',
                    number: '',
                    departure_loc: '',
                    destination_loc: '',
                    note: '',
                    case: '',
                    annex: '',
                    remark: '',
                    unit: '',
                    chooseTimeIndex: ''
                }
            ],
            cInfo:[],
            concatList: []
        }
    },
    mounted(){
        this.getUseto()
        this.getUnit()
        this.getCinfo()
        this.getInfo()
        this.maxDate = new Date(moment().add(14, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'))
    },
    methods:{
        delIssue(index){
            this.formList.splice(index,1)
        },
        getInfo(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/setting/Information',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),

                }).then( res=>{                    
                    console.log(res)
                    this.concatList = res.data.data.info
                    this.formList[0].contacts = res.data.data.info.alias
                    this.formList[0].telephone = res.data.data.info.contact_info
                })
        },
        getCinfo(){
            let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/basis/info',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)       
                            this.cInfo = res.data.data.info                
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
        },
        chooseTime(e){
            this.chooseTimePickerShow = false,
            this.formList[this.chooseTimeIndex].departure_time = moment(e).format('YYYY-MM-DD HH:mm')
        },
        cancelTime(){
            this.chooseTimePickerShow = false
        },
        submit(){
            var time = 0
            for(let i in this.formList){
            
                if(this.formList[i].departure_place	 == ''){
                    Toast.fail('请输入出发地')
                }else if(this.formList[i].destination == ''){
                    Toast.fail('请输入目的地')
                }else if(this.formList[i].departure_time == ''){
                    Toast.fail('请选择出发时间')
                }else if(this.formList[i].use_to == '' || this.formList[i].use_to == undefined){
                    Toast.fail('请选择约车用途')
                }else if(this.formList[i].telephone == ''){
                    Toast.fail('请输入联系电话')
                }else if(this.formList[i].fee == ''){
                    Toast.fail('请输入约车费用')
                }else if(this.formList[i].unit == ''){
                    Toast.fail('请选择货币')
                }else if(this.formList[i].duration == ''){
                    Toast.fail('请输入用车时长')
                }else if(this.formList[i].duration < 1){
                    Toast.fail('用车时长必须大于1天')
                }else if(this.formList[i].duration > 7){
                    Toast.fail('用车时长必须小于7天')
                }else{
                    time = time + 1
                    if(time == this.formList.length ){
                        this.subInfo()
                    }
                }
            }
        },
        subInfo(){
            if(this.formList.length == 1 ){
                let token = localStorage.getItem('token')
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/create',
                    {
                        nonce: nonce,
                        token: token,
                        multi: 0,
                        departure_place: this.formList[0].departure_place,
                        departure_time:  this.formList[0].departure_time.split(':')[0] + ':' +  this.formList[0].departure_time.split(':')[1],
                        destination: this.formList[0].destination,
                        use_to: this.formList[0].use_to,
                        telephone: this.formList[0].telephone || this.cInfo.contact_info,
                        fee: this.formList[0].fee,
                        unit: this.formList[0].unit,
                        duration:  this.formList[0].duration,
                        contacts: this.formList[0].contacts ||  this.cInfo.director,
                        wechat: this.formList[0].wechat,
                        departure_loc: this.formList[0].departure_loc,
                        number: this.formList[0].number,
                        destination_loc:  this.formList[0].destination_loc,
                        note: this.formList[0].note,
                        case: this.formList[0].case,
                        annex: this.formList[0].annex,
                        remark: this.formList[0].remark,
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            Toast.success('发布成功');
                            this.formList = []
                            let obj = {
                                departure_place	: '',
                                destination: '',
                                departure_time: '',
                                use_to: '',
                                telephone: this.concatList.contact_info,
                                wechat: '',
                                contacts: this.concatList.alias,
                                fee: '',
                                duration: '',
                                number: '',
                                departure_loc: '',
                                destination_loc: '',
                                note: '',
                                case: '',
                                annex: '',
                                remark: '',
                                unit: ''
                            }
                            this.formList.push(obj)                     
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })

            }else{
                let num = 0
                for(let i in this.formList){
                    num +=1
                    if(this.formList[i].contacts == ''){
                        this.formList[i].contacts = this.cInfo.director
                    }
                    if(this.this.formList[i].telephone == ''){
                        this.this.formList[i].telephone = this.cInfo.contact_info
                    }
                    this.formList[i].departure_time = moment(this.formList[i].departure_time).format('YYYY-MM-DD HH:mm')
                }
                if(num == this.formList.length){
                    console.log(this.formList)
                    let token = localStorage.getItem('token')
                    let nonce = Math.round(new Date() / 60000).toString(32)
                    this.$axios.post(
                        this.url + '/order/create',
                        {
                            nonce: nonce,
                            token: token,
                            multi: 1,
                            data: this.formList
                        }).then( res=>{                    
                            if(res.data.code == 0){
                                Toast.success('发布成功');
                                this.formList = []
                                let obj = {
                                    departure_place	: '',
                                    destination: '',
                                    departure_time: '',
                                    use_to: '',
                                    telephone:  this.concatList.contact_info,
                                    wechat: '',
                                    contacts: this.concatList.alias,
                                    fee: '',
                                    duration: '',
                                    number: '',
                                    departure_loc: '',
                                    destination_loc: '',
                                    note: '',
                                    case: '',
                                    annex: '',
                                    remark: '',
                                    unit: ''
                                }
                                this.formList.push(obj)                     
                            }else if(res.data.code == 20001){
                                this.$router.replace('/login')
                            }else{
                                Toast.fail(res.data.msg);
                            }
                        })
                    // let nonce = Math.round(new Date() / 60000).toString(32)
                    // issueOrderMany(nonce,token,1,this.formList).then((res=>{
                    // if(res.data.code == 0){
                    //     this.$message.success('发布成功')
                    //     this.formList = []
                    //     let obj = {
                    //         departure_place	: '',
                    //         destination: '',
                    //         departure_time: '',
                    //         use_to: '',
                    //         telephone: '',
                    //         wechat: '',
                    //         contacts: '',
                    //         fee: '',
                    //         duration: '',
                    //         number: '',
                    //         departure_loc: '',
                    //         destination_loc: '',
                    //         note: '',
                    //         case: '',
                    //         annex: '',
                    //         remark: '',
                    //         unit: ''
                    //     }
                    //         this.formList.push(obj)
                    //     }else if(res.data.code == 20001){
                    //         this.$message.error(res.data.msg)
                    //         this.$router.replace('/login')
                    //     }else{
                    //         this.$message.error(res.data.msg)
                    //     }
                    // }))
                }

            }
        },
        getUnit(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/basis/feeUnitSel',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.columns2 = res.data.data
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
        getUseto(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/basis/useToSel',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.columns = res.data.data
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
        addOrder(){
            let obj = {
                departure_place	: '',
                destination: '',
                departure_time: '',
                use_to: '',
                telephone:  this.concatList.contact_info,
                wechat: '',
                contacts: this.concatList.alias,
                fee: '',
                duration: '',
                number: '',
                departure_loc: '',
                destination_loc: '',
                note: '',
                case: '',
                annex: '',
                remark: '',
                unit: ''
            }
            this.formList.push(obj)
        },
        chooseCurrency(){
            this.chooseCurrencyPickerShow = true
        },
        chooseTimePicker(index){
            console.log(index)
            this.chooseTimeIndex = index
            this.chooseTimePickerShow = true
        },
        chooseTypePicker(){
            this.chooseTypePickerShow = true
        }
    }
}
</script>