<template>
    <div v-html="agreement">
      
    </div>
</template>
<script>
export default {
    data() {
        return{
            agreement: ''
        }
    },
    mounted(){
        this.getAgreement()
    },
    methods:{
        getAgreement(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/user/getAgreement',
                {
                    nonce: nonce,
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.agreement = res.data.data.cont
                    }
                })
        }
    }
}
</script>