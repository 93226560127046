<template>
    <div style="padding-bottom: 20px">
        <van-nav-bar title="订单详细" left-text="返回" left-arrow @click-left="onClickLeft"/>
        <div style="margin-top: 10px;font-weight:bold;margin-left: 2.5%">预约单号：{{ detail.order_sn }}</div>
        <div style="margin-top: 10px;font-weight:bold;margin-left: 2.5%">发布时间：{{ detail.created_at }}</div>
        <div style="margin-top: 10px;font-weight:bold;margin-left: 2.5%">当前状态：
            <span v-if="detail.status == 11">已发布</span>
            <span v-else-if="detail.status == 0">已取消</span>
            <span v-else-if="detail.status == 2">已超时</span>
            <span v-else-if="detail.status == 13">已接单</span>
            <span v-else-if="detail.status == 15">进行中</span>
            <span v-else-if="detail.status == 19">已结束</span>
        </div>
        <van-cell-group v-if="detail.status == 19" style="margin-top: 10px">
            <van-field  v-for="item in rateList" :key="item.tid" input-align="right"  :label="item.text" >
                <van-rate slot="extra" :readonly="isRate" :size="25" color="#ffd21e" v-model="item.score" />
            </van-field>
            <van-field v-if="isRate == false"  input-align="right"  >
                <div slot="input"></div>
                <van-button @click="rateOrder" slot="button" type="info">提交评价</van-button>
            </van-field>
            <van-field  input-align="right" disabled v-model="detail.departure_place" label="出发地" placeholder="请输入用户名" />
            <van-field  input-align="right" disabled v-model="detail.departure_time" label="出发时间" placeholder="请输入用户名" />
            <van-field  input-align="right" disabled v-model="detail.destination" label="目的地" placeholder="请输入用户名" />
            <van-field  input-align="right" disabled v-model="detail.use_to" label="约车用途" placeholder="请输入用户名" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.telephone" label="联系电话" placeholder="请输入用户名" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.wechat" label="联系微信号" placeholder="请输入联系微信号" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.contacts" label="联系人" placeholder="请输入联系人" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.fee" label="约车费用" placeholder="请输入约车费用" >
                <template #button>
                    <van-radio-group  :disabled="disabled"  v-model="detail.unit" direction="horizontal">
                        <van-radio v-for="item in columns2" :key="item" :name="item">{{ item }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field  input-align="right" :disabled="disabled" v-model="detail.duration" label="用车时长" placeholder="请输入用车时长" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.number" label="乘车人数" placeholder="请输入乘车人数" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.departure_loc" label="具体出发点" placeholder="请输入具体出发点" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.destination_loc" label="具体目的地" placeholder="请输入具体目的地" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.note" label="对车辆要求" placeholder="请输入对车辆要求" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.case" label="乘客特殊情况" placeholder="请输入乘客特殊情况" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.annex" label="携带行李状况" placeholder="请输入携带行李状况" />
            <van-field  input-align="right" :disabled="disabled" v-model="detail.remark" label="其他相关备注" placeholder="请输入其他相关备注" />
        </van-cell-group>
        <div  v-if="dirverInfo != ''" style="margin-top: 10px;font-weight:bold;margin-left: 2.5%">接单车辆信息</div>
        <van-cell-group style="margin-top: 10px">
            
            <van-field v-if="dirverInfo != ''"  input-align="right" disabled v-model="dirverInfo.name" label="注册司机" placeholder="请输入注册司机" />
            <van-field v-if="detail.taking_time" input-align="right" disabled v-model="detail.taking_time" label="接单时间" placeholder="请输入接单时间" />
            <van-field  v-if="dirverInfo != ''"  input-align="right" disabled v-model="dirverInfo.contacts	" label="联系电话" placeholder="请输入联系电话" />
            <van-field v-if="dirverInfo != ''"  input-align="right" disabled v-model="dirverInfo.wechat	" label="联系微信" placeholder="请输入联系微信" />
            <van-field  v-if="dirverInfo != ''" input-align="right" :disabled="disabled" v-model="carInfo.license_tag	" label="车辆牌照" placeholder="请输入车辆牌照" />
            <van-field  v-if="carInfo != ''" input-align="right" :disabled="disabled" v-model="carInfo.car_no" label="车牌号" placeholder="请输入车牌号" />
            <van-field  v-if="carInfo != ''"  input-align="right" :disabled="disabled" v-model="carInfo.vehicle_model	" label="车型" placeholder="请输入车型" />
            <van-field  v-if="carInfo != ''" input-align="right" :disabled="disabled" v-model="carInfo.seats" label="座位数" placeholder="请输入座位数" > </van-field>
            <van-field  v-if="certificateInfo != ''" input-align="right" :disabled="disabled"  label="车检书照片">
                <div slot="extra">
                    <van-image width="100"   height="100" :src="certificateInfo.inspection"/>
                </div>

            </van-field>
            <van-field  v-if="certificateInfo != ''"  input-align="right" :disabled="disabled" label="保险单照片" >
                <div slot="extra">
                    <van-image width="100"   height="100" :src="certificateInfo.policy"/>
                </div>
            </van-field>
            <van-field  v-if="certificateInfo != ''" input-align="right" :disabled="disabled" v-model="detail.departure_loc" label="驾照照片"  >
                <div slot="extra">
                    <van-image width="100"   height="100" :src="certificateInfo.license"/>
                </div>
            </van-field>
        </van-cell-group>
        <div v-if="detail.status == 11" style="margin-top: 10px;display:flex;justify-content:space-around">
            <van-button style="width: 30%" @click="cancel" block type="danger">取消订单</van-button>
            <van-button style="width: 30%" @click="save" block type="info">保存信息</van-button>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { Dialog } from 'vant';

export default {
    data(){
        return{
            value: '',
            orderSn: '',
            detail: [],
            columns2: [],
            disabled: false,
            carInfo: [],
            rateList: [],
            isRate: false,
            dirverInfo: [],
            certificateInfo:[]
        }
    },
    mounted(){
        this.orderSn = this.$route.query.orderSn
        this.getDetail()
        this.getUnit()
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1)
        },
        cancel(){
            Dialog.confirm({
            title: '确认取消',
            message: '取消后预约单将变为已取消状态，是否确定取消？',
            })
            .then(() => {
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/cancel',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        order_sn: this.detail.order_sn
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            Toast.success('取消成功')
                            this.$router.replace('/orderManagement')
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            })
            .catch(() => {
                // on cancel
            });
        },
        save(){
            if(this.detail.departure_place	 == ''){
                Toast.fail('请输入出发地')
            }else if(this.detail.destination == ''){
                Toast.fail('请输入目的地')
            }else if(this.detail.departure_time == ''){
                Toast.fail('请选择出发时间')
            }else if(this.detail.use_to == '' || this.detail.use_to == undefined){
                Toast.fail('请选择约车用途')
            }else if(this.detail.telephone == ''){
                Toast.fail('请输入联系电话')
            }else if(this.detail.fee == ''){
                Toast.fail('请输入约车费用')
            }else if(this.detail.unit == ''){
                Toast.fail('请选择货币')
            }else if(this.detail.duration == ''){
                Toast.fail('请输入用车时长')
            }else{
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/update',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        order_sn: this.detail.order_sn,
                        telephone: this.detail.telephone,
                        fee: this.detail.fee,
                        unit: this.detail.unit,
                        duration: this.detail.duration,
                        contacts: this.detail.contacts,
                        wechat: this.detail.wechat,
                        departure_loc: this.detail.departure_loc,
                        number: this.detail.number,
                        destination_loc: this.detail.destination_loc,
                        note: this.detail.note,
                        case: this.detail.case,
                        annex: this.detail.annex,
                        remark: this.detail.remark
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            Toast.success('保存成功')
                            this.$router.replace('/orderManagement')
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }
        },
        getUnit(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/basis/feeUnitSel',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.columns2 = res.data.data
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
        getDetail(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/order/detail',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                    order_sn: this.orderSn
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.detail = res.data.data.order
                        if(res.data.data.snap){
                            this.carInfo = res.data.data.snap.car_info
                            this.dirverInfo = res.data.data.snap.driver_profile
                            this.certificateInfo = res.data.data.snap.credential
                        }
                        if(res.data.data.order.status != 11){
                            this.disabled = true
                        }
                        if(!res.data.data.comment){
                            this.getRateList()
                        }else{
                            this.rateList = res.data.data.comment.detail
                            this.isRate = true
                        }
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
        getRateList(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/order/judgmentOpts',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                    order_sn: this.orderSn
                }).then( res=>{                    
                    if(res.data.code == 0){
                        console.log(res)
                        this.isRate = false
                        this.rateList = res.data.data.list
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
        rateOrder(){
            let nonce = Math.round(new Date() / 60000).toString(32)
            this.$axios.post(
                this.url + '/order/judgment',
                {
                    nonce: nonce,
                    token: localStorage.getItem('token'),
                    order_sn: this.orderSn,
                    cmt: this.rateList
                }).then( res=>{                    
                    if(res.data.code == 0){
                        Toast.success('评价成功');
                        this.getDetail()
                    }else if(res.data.code == 20001){
                        this.$router.replace('/login')
                    }else{
                        Toast.fail(res.data.msg);
                    }
                })
        },
    }
}
</script>