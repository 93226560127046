<template>
    <div>
        <p style="margin-top: 25vh;font-size: 30px;text-align:center">预约车辆发布系统</p>
        <div style="width:95%;margin-left: 2.5%">
            <van-cell-group :border="false" >
                <van-field :border="false" v-model="account" label="用户名" placeholder="请输入用户名" />
                <van-field :border="false"  v-model="pwd" label="密码" type="password" placeholder="请输入密码" />
            </van-cell-group>
            <div style="padding:10px 16px;width:95%;display:flex;font-size:12px;color: #999;margin-top: 10px">
                <div @click="goRegister" style=";">
                    新用户注册
                </div>
                <div @click="goForgetPwd" style="margin-left: 15px">忘记密码</div>
            </div>
            <div style="text-align: center;">
                <van-button type="info" size="large" @click="login" style="width: 80%;margin-top: 10px;">登录</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return{
            account: '',
            pwd: ''
        }
    },
    methods:{
        goRegister(){
            this.$router.push('/register')
        },
        goForgetPwd(){
            this.$router.push('/forgetPwd')
        },
        login(){
            if(this.account == ''){
                Toast.fail('请输入账号');
            }else if(this.pwd == ''){
                Toast.fail('请输入密码');
            }else{
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/user/login',
                    {
                        nonce: nonce,
                        username: this.account,
                        password: this.pwd
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            localStorage.setItem('token', res.data.data.user.token)
                            localStorage.setItem('auth', res.data.data.user.is_super)
                            this.$router.replace('/')
                        }else{
                            Toast(res.data.msg)
                        }
                    })
            }
        }
    }
}
</script>