<template>
    <div>
        <van-cell-group>
            <van-cell title="绑定企业信息" @click="goBindInfo"   is-link />
            <van-cell title="添加员工账号" @click="goAddAccount"   is-link />
        </van-cell-group>
        <van-button type="danger" @click="logout" style="margin-top: 70vh;width: 80%;margin-left:10%">退出登录</van-button>
        <van-tabbar v-model="active">
            <van-tabbar-item to="/"  icon="records-o">发布预约</van-tabbar-item>
            <van-tabbar-item to="/orderManagement" icon="orders-o">用车单管理</van-tabbar-item>
            <van-tabbar-item to='/setting' icon="setting-o">系统设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return{
            active: 2,
            auth: localStorage.getItem('auth')
        }
    },
    methods:{
        logout(){
            localStorage.removeItem('token')
            localStorage.removeItem('auth')
            this.$router.replace('login')
        },
        goBindInfo(){
            if(this.auth == 1){
                this.$router.push('/bindCompanyInfo')
            }else{
                Toast('该功能只对管理员开放')
            }
        },
        goAddAccount(){
            if(this.auth == 1){
                this.$router.push('/bindAccount')
            }else{
                Toast('该功能只对管理员开放')
            }
        }
    }
}
</script>