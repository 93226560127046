<template>
    <div style="padding-bottom: 48px;background:#f5f5f5;min-height:100vh">
        <van-tabs color="#1989fa" @change="changeType" v-model="typeActive">
            <van-tab title="已发布"></van-tab>
            <van-tab title="已接单"></van-tab>
            <van-tab title="进行中"></van-tab>
            <van-tab title="已取消"></van-tab>
            <van-tab title="已超时"></van-tab>
            <van-tab title="已结束"></van-tab>
            <div>
                <van-list  v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div v-for="item in list" :key="item.order_sn" @click="goOrderDetail(item.order_sn,item.status)" style="margin-top: 20px;background:#f5f5f5">
                        <van-cell-group :border="false"  style="color:#000;background:#f5f5f5">
                            <van-cell :border="false"  style="color: #000 !important;"  v-model="item.order_sn" disabled title="预约单号"  placeholder="请输入用户名" />
                            <van-cell :border="false" v-model="item.created_at"  title="发布时间" placeholder="请输入用户名" />
                            <van-cell :border="false" v-model="item.create_user"  title="发布员工" placeholder="请输入用户名" />
                            <van-cell :border="false" v-model="value"  title="当前状态" placeholder="请输入用户名" >
                                <span v-if="item.status == 11">已发布</span>
                                <span v-else-if="item.status == 0">已取消</span>
                                <span v-else-if="item.status == 2">已超时</span>
                                <span v-else-if="item.status == 13">已接单</span>
                                <span v-else-if="item.status == 15">进行中</span>
                                <span v-else-if="item.status == 19">已结束</span>
                            </van-cell>

                        </van-cell-group>
                    </div>
                    
                </van-list>
            </div>
        </van-tabs>
        <van-tabbar v-model="active">
            <van-tabbar-item to="/" icon="records-o">发布预约</van-tabbar-item>
            <van-tabbar-item to="/orderMangement" icon="orders-o">用车单管理</van-tabbar-item>
            <van-tabbar-item to='/setting' icon="setting-o">系统设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
import { Toast } from 'vant'
export default {
    data() {
        return{
            active: 1,
            value: '11111111111',
            typeActive: 0,
            loading: false,
            finished: false,
            page: 0,
            pageSize: 5,
            list: []
        }
    },
    mounted(){
    },
    methods:{
        goOrderDetail(orderSn,status){
            this.$router.push({
                path: '/orderDetail',
                query:{
                    orderSn : orderSn,
                    status: status
                }
            })
        },
        changeType(){
            this.list = []
            this.page = 0
            this.onLoad()
        },
        onLoad(){
            if(this.typeActive == 0){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/xiList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                            if(res.data.data.pager.last_page == 0){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            this.loading = false;
                            this.finished = true
                            Toast.fail(res.data.msg);
                        }
                    })
            }else if(this.typeActive == 1){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/xiiiList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }else if(this.typeActive == 2){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/xvList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }else if(this.typeActive == 3){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/cancelList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }else if(this.typeActive == 4){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/iiList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }else if(this.typeActive == 5){
                let nonce = Math.round(new Date() / 60000).toString(32)
                this.$axios.post(
                    this.url + '/order/xixList',
                    {
                        nonce: nonce,
                        token: localStorage.getItem('token'),
                        page: this.page += 1,
                        page_size: this.pageSize
                    }).then( res=>{                    
                        if(res.data.code == 0){
                            console.log(res)
                            this.list = this.list.concat(res.data.data.list)
                            this.loading = false;
                            if(res.data.data.pager.current_page == res.data.data.pager.last_page){
                                this.loading = false;
                                this.finished = true
                            }
                        }else if(res.data.code == 20001){
                            this.$router.replace('/login')
                        }else{
                            Toast.fail(res.data.msg);
                        }
                    })
            }
        }
    }

}
</script>
<style>
.van-field--disabled .van-field--disabled .van-field__label .van-cell__title .van-field__label{
    color: #000!important
}
</style>