import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import register from '../views/register.vue'
import index from '../views/index.vue'
import orderManagement from '../views/orderManagement.vue'
import setting from '../views/setting.vue'
import agreement from '../views/agreement.vue'
import bindCompanyInfo from '../views/bindCompanyInfo.vue'
import bindAccount from '../views/bindAccount.vue'
import orderDetail from '../views/orderDetail.vue'
import forgetPwd from '../views/forgetPwd.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name:  '登录',
    component: login
  },
  {
    path: '/forgetPwd',
    name:  '忘记密码',
    component: forgetPwd
  },
  {
    path: '/orderDetail',
    name:  '订单详细',
    component: orderDetail
  },
  {
    path: '/setting',
    name:  '系统设置',
    component: setting
  },
  {
    path: '/bindAccount',
    name:  '添加员工账号',
    component: bindAccount
  },
  {
    path: '/bindCompanyInfo',
    name:  '绑定企业信息',
    component: bindCompanyInfo
  },
  {
    path: '/register',
    name:  '注册',
    component: register
  },
  {
    path: '/agreement',
    name:  '使用协议',
    component: agreement
  },
  {
    path: '/orderManagement',
    name:  '用车单管理',
    component: orderManagement
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
